import React from "react";
import PropTypes from "prop-types";

import { ActionBox, Hero, Link } from "../components/common";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ConnectionLost from "../assets/images/vectors/connection-lost.svg";
import { getStaticSEOImage } from "../helpers";

function NotFoundPage({ location }) {
  return (
    <Layout>
      <SEO
        title="404: Not found"
        image={getStaticSEOImage("404")}
        path={location?.pathname}
      />
      <section className="mt-12 md:mt-24 md:mb-32 mb-16">
        <Hero
          subTitle="What? Wait, No!"
          title={
            <>
              We... <span className="text-ui-gray-plus-2">(sigh)</span> Lost
              This Page :(
            </>
          }
          description={
            <>
              The URL has been mistyped or deleted. We applogize for this
              inconvenience and offering you a{" "}
              <strong>10% discount code</strong> on all of our products. Just
              use code <strong>404</strong> at checkout!
            </>
          }
        />
      </section>
      <section className="md:mb-32 mb-30">
        <div className="flex items-center justify-center">
          <img alt="not-found" src={ConnectionLost} />
        </div>
      </section>
      <section className="mb-24">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12 md:gap-8">
          <ActionBox icon="vg-home">
            Return to
            <br />
            <Link className="no-underline hover:underline" to="/">
              Vackground Home
            </Link>
          </ActionBox>
          <ActionBox icon="vg-grid">
            Explore
            <br />
            <Link className="no-underline hover:underline" to="/products">
              Vackground Products
            </Link>
          </ActionBox>
          <ActionBox
            className="col-span-1 md:col-span-2 lg:col-span-1"
            icon="vg-mail"
          >
            Send Query at
            <br />
            <a
              className="no-underline hover:underline text-brand-primary"
              href="mailto:hi@vackground.com"
            >
              hi@vackground.com
            </a>
          </ActionBox>
        </div>
      </section>
    </Layout>
  );
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
};

export default NotFoundPage;
